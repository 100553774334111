<template>
    <tr v-bind:class="{'d-none': !showRow}">
        <td>
            <div class="d-flex align-items-center">
                <div v-if="item.children.length">
                    <span class="cursor-pointer collapsible rotate" v-bind:class="{collapsed: !openedExpandRow(itemIndex)}" v-on:click="handleExpandRow(itemIndex)">
                        <div class="me-3 rotate-90">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/arrows/arr071.svg"/>
                            </span>
                        </div>
                    </span>
                </div>
                <div v-else-if="depth != 0" class="row-indent" v-bind:style="{'padding-left': (depth * 5)+ 'px', 'margin-top': '-8px'}">
                    <span class="svg-icon svg-icon-4 me-2">
                        <inline-svg src="/media/icons/duotune/arrows/arr049.svg" />
                    </span>
                    <span v-bind:style="{'padding-left': (depth * 5)+ 'px'}"></span>
                </div>
                <div>
                    <label class="w-150px">
                        <router-link :to="'/ecommerce/product-management/product/save/' + item.product.id" target="_blank" class="fw-bold text-gray-600 text-hover-primary">
                            {{ item.title }}
                        </router-link>
                    </label>
                    <div class="fw-normal text-gray-600" v-if="item.variant_combination">{{ getVariantCombination(item.variant_combination) }}</div>
                </div>
            </div>
        </td>
        <td>{{ item.product.type.name }}</td>
        <td>{{ item.product.group.name }}</td>
        <td>{{ item.quantity }}</td>
        <td>{{ item.total + ' ' + currency.suffix }}</td>
        <td colspan="2" v-if="item.product.type_id == 2">
          <div class="d-flex align-items-center">
              <div>
                  <div>
                      <span class="fw-bolder fs-7">{{ $t('pages.ecommerce.order.detail.product.cols.usageMinDate') }}: </span>
                      <span class="fs-7">{{ item.usage_min_date ? $moment(item.usage_min_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}</span>
                  </div>
                  <div>
                      <span class="fw-bolder fs-7">{{ $t('pages.ecommerce.order.detail.product.cols.usageMaxDate') }}: </span>
                      <span class="fs-7">{{ item.usage_max_date ? $moment(item.usage_max_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}</span>
                  </div>
              </div>
              <div class="ms-4">
                  <el-button class="editUsageDateButton" type="primary" @click="eventBus.$emit('changeItemUsageDate', item)"><i class="bi bi-pencil"></i></el-button>
              </div>
          </div>
        </td>
        <td v-else>-</td>
    </tr>
    <TreeProduct
        v-for="(child, childIndex) in item.children"
        :item="child"
        :itemIndex="childIndex"
        :key="childIndex"
        :depth="depth + 1"
        :currency="currency"
        :showRow="openedExpandRow(itemIndex) && showRow"
    ></TreeProduct>
</template>

<script>
import TreeProduct from '@/components/ecommerce/order/detail/treeProduct.vue';

export default {
    name: "treeProduct",
    props: ['item', 'itemIndex', 'depth', 'currency', 'showRow'],
    components: {
        TreeProduct
    },
    data() {
        return {
            expandRow: {
                closed: []
            }
        }
    },
    methods: {
        getVariantCombination(variantCombination){
            let variants = [];

            variantCombination.variants.forEach((variant) => {
                variants.push(variant.item.name);
            });

            return variants.join(' / ');
        },
        openedExpandRow(index) {
            return !this.expandRow.closed.includes(index);
        },
        handleExpandRow(itemIndex) {
            let x = this.expandRow.closed.indexOf(itemIndex);
            if(x !== -1) {
                this.expandRow.closed.splice(x, 1);
            } else {
                this.expandRow.closed.push(itemIndex);
            }
        }
    }
}
</script>

<style>
    .editUsageDateButton{
        padding: 6px 9px;
        min-height: unset !important;
    }
</style>