<template>
    <div v-if="!$root.filterWithUrl" class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/ecommerce/order-management/order" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("menu.orders") }}
        </router-link>
    </div>
    <div class="d-flex flex-column flex-lg-row" v-if="Object.keys(order).length">
        <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
            <div class="card card-flush pt-3 mb-5 mb-xl-10">
                <div class="card-header">
                    <div class="card-title">
                        <h2 class="fw-bolder">{{ $t('pages.ecommerce.order.detail.orderDetails') }}</h2>
                    </div>
                </div>

                <div class="card-body pt-3">
                    <div class="mb-10">
                        <h5 class="mb-4">{{ $t('pages.ecommerce.order.detail.product.title') }}</h5>
                        <div class="table-responsive">
                            <table class="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                                <thead>
                                <tr class="border-bottom border-gray-200 text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                    <th class="min-w-150px">{{ $t('pages.ecommerce.order.detail.product.cols.name') }}</th>
                                    <th class="min-w-150px">{{ $t('pages.ecommerce.order.detail.product.cols.type') }}</th>
                                    <th class="min-w-150px">{{ $t('pages.ecommerce.order.detail.product.cols.group') }}</th>
                                    <th class="">{{ $t('pages.ecommerce.order.detail.product.cols.quantity') }}</th>
                                    <th class="min-w-125px">{{ $t('pages.ecommerce.order.detail.product.cols.price') }}</th>
                                    <th class="" colspan="2">{{ $t('pages.ecommerce.order.detail.product.cols.usageDate') }}</th>
                                </tr>
                                </thead>
                                <tbody class="fw-bold text-gray-600">
                                    <TreeProduct
                                        v-for="(product, productIndex) in order.items"
                                        :item="product"
                                        :itemIndex="productIndex"
                                        :key="productIndex"
                                        :showRow="true"
                                        :depth="0"
                                        :currency="order.currency">
                                    </TreeProduct>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="mb-0">
                        <div class="d-flex justify-content-center justify-content-sm-end py-5">
                            <div class="summary-table">
                                <table class="table fs-6 fw-bold gy-4 gx-5 m-0 w-sm-350px">
                                    <tr>
                                        <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.summary.subTotal') }}</td>
                                        <td>:</td>
                                        <td class="text-gray-800 text-end">{{ order.subtotal + ' ' + order.currency.suffix }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.summary.tax') }}</td>
                                        <td>:</td>
                                        <td class="text-gray-800 text-end">{{ order.tax + ' ' + order.currency.suffix }}</td>
                                    </tr>
                                    <tr v-if="order.shipping_detail && order.shipping_detail.shipping_total > 0">
                                        <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.summary.shipping') }}</td>
                                        <td>:</td>
                                        <td class="text-gray-800 text-end">{{ order.shipping_detail.shipping_total + ' ' + order.currency.suffix }}</td>
                                    </tr>
                                    <tr v-if="order.payment_detail && order.payment_detail.installment_commission">
                                        <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.summary.installmentCommission') }}</td>
                                        <td>:</td>
                                        <td class="text-gray-800 text-end">{{ order.payment_detail.installment_commission + ' ' + order.currency.suffix }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.summary.total') }}</td>
                                        <td>:</td>
                                        <td class="text-gray-800 text-end">{{ getOrderSummaryTotal() + ' ' + order.currency.suffix }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <custom-table
                v-if="order.payment_detail && order.payment_detail.payment_method && order.payment_detail.payment_method.type_id == 2"
                :title="$t('pages.ecommerce.order.paymentCollection.title')"
                :subTitle="$t('pages.ecommerce.order.paymentCollection.subTitle')"
                rowKey="id"
                class="mb-5 mb-xl-10"
                :items="orderPaymentCollection.table.data"
                :loading="orderPaymentCollection.table.loading"
                :columns="orderPaymentCollection.fields"
                :actions="orderPaymentCollection.actions"
                @action="handleOrderPaymentCollectionClickAction">
                <template v-slot:createdBy="{ row: record }">
                    {{ record.admin.firstname + ' ' + record.admin.lastname }}
                </template>
                <template v-slot:amount="{ row: record }">
                    {{ record.amount }} {{ order.currency.suffix }}
                </template>
                <template v-slot:note="{ row: record }">
                    {{ record.note ?? "-" }}
                </template>
                <template v-slot:createdAt="{ row: record }">
                    {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
                </template>
                <template v-slot:actions="{ row: record }">
                    <div class="d-flex justify-content-end">
                        <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteOrderPaymentCollectionRecord([record.id])">
                            <template #reference>
                                <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                    <span class="svg-icon svg-icon-3">
                                        <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                    </span>
                                </a>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </custom-table>
            <custom-table v-if="orderDiscountCoupon.table.data.length"
                :title="$t('pages.ecommerce.order.discountCoupon.title')"
                :subTitle="$t('pages.ecommerce.order.discountCoupon.subTitle')"
                rowKey="id"
                class="mb-5 mb-xl-10"
                :items="orderDiscountCoupon.table.data"
                :columns="orderDiscountCoupon.fields">
                <template v-slot:type="{ row: record }">
                    {{ $t('pages.ecommerce.discountCoupon.couponTypes.' + orderDiscountCoupon.types[record.discount_coupon.discount_type]) }}
                </template>
                <template v-slot:value="{ row: record }">
                    {{ record.discount_coupon.discount_value + (record.discount_coupon.discount_type == 2 ? '%' : '') }}
                </template>
                <template v-slot:total="{ row: record }">
                    {{ record.discount_total }} {{ order.currency.suffix }}
                </template>
            </custom-table>
            <custom-table
                :title="$t('pages.ecommerce.order.note.title')"
                :subTitle="$t('pages.ecommerce.order.note.subTitle')"
                rowKey="id"
                :items="orderNote.table.data"
                :loading="orderNote.table.loading"
                :columns="orderNote.fields"
                :actions="orderNote.actions"
                @action="handleOrderNoteClickAction">
                <template v-slot:createdBy="{ row: record }">
                    {{ (record.user_area_id === 3 ? $t('common.system') : record.owner.firstname + ' ' + record.owner.lastname) }}
                </template>
                <template v-slot:createdAt="{ row: record }">
                    {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
                </template>
                <template v-slot:actions="{ row: record }">
                    <div class="d-flex justify-content-end">
                        <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteOrderNoteRecord([record.id])" v-if="record.is_deletable">
                            <template #reference>
                                <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                    <span class="svg-icon svg-icon-3">
                                        <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                    </span>
                                </a>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </custom-table>
        </div>

        <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xl-300px mb-10 order-1 order-lg-2">
            <div class="card card-flush pt-3 mb-5 mb-lg-10">
                <div class="card-header">
                    <div class="card-title">
                        <h2>{{ $t('pages.ecommerce.order.detail.orderAndPayment.title') }}</h2>
                    </div>
                </div>

                <div class="card-body pt-0 fs-6">
                    <div class="mb-7">
                        <h5 class="mb-3">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.orderNumber') }}</h5>

                        <div class="mb-0">
                            <span class="fw-bold text-gray-600">{{ order.id }}</span>
                        </div>
                    </div>

                    <div class="separator separator-dashed mb-7"></div>

                    <div class="mb-10">
                        <h5 class="mb-3">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.orderDate') }}</h5>

                        <div class="mb-0">
                            <span class="fw-bold text-gray-600">{{ $moment(order.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                        </div>
                    </div>

                    <div class="separator separator-dashed mb-7"></div>

                    <div class="mb-10">
                        <h5 class="mb-3">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.orderStatus') }}</h5>

                        <div class="mb-0">
                            <div class="d-flex align-items-center mb-1 justify-content-between">
                                <span class="fw-bold text-gray-600">{{ order.status.name }}</span>
                                <a v-on:click="changeOrderStatus" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                    <span class="svg-icon svg-icon-3">
                                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <template v-if="order.payment_detail">
                        <div class="separator separator-dashed mb-7"></div>

                        <div class="mb-10">
                            <h5 class="mb-3">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.title') }}</h5>

                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2">
                                <tr class="">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.method') }}:</td>
                                    <td class="text-gray-800">{{ order.payment_detail.payment_method.name }}</td>
                                </tr>
                                <tr class="">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.paidTotal') }}:</td>
                                    <td class="text-gray-800">{{ order.payment_detail.paid_total + ' ' + order.currency.suffix }}</td>
                                </tr>
                                <tr class="" v-if="order.payment_detail && order.payment_detail.payment_method && order.payment_detail.payment_method.type_id == 2">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.remainingCollectAmount') }}:</td>
                                    <td class="text-gray-800">{{ order.payment_detail.remaining_collect_amount + ' ' + order.currency.suffix }}</td>
                                </tr>
                                <tr class="" v-if="order.payment_detail.installment != null">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.installment') }}:</td>
                                    <td class="text-gray-800">{{ order.payment_detail.installment == 0 ? $t('common.cash') : order.payment_detail.installment }}</td>
                                </tr>
                                <tr class="" v-if="order.payment_detail.installment_commission">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.installmentCommission') }}:</td>
                                    <td class="text-gray-800">{{ order.payment_detail.installment_commission + ' ' + order.currency.suffix }}</td>
                                </tr>
                                <template v-if="order.payment_detail.payment_sub_method">
                                    <tr class="">
                                        <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.subMethod.cols.name') }}:</td>
                                        <td class="text-gray-800">{{ order.payment_detail.payment_sub_method.payload.name }}</td>
                                    </tr>
                                    <tr class="">
                                        <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.subMethod.cols.accountNumber') }}:</td>
                                        <td class="text-gray-800">{{ order.payment_detail.payment_sub_method.payload.account_number }}</td>
                                    </tr>
                                </template>
                                <template v-if="order.payment_detail.payment_detail">
                                    <tr class="">
                                        <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.paymentDetail.bank') }}:</td>
                                        <td class="text-gray-800">{{ order.payment_detail.payment_detail.bank_name }}</td>
                                    </tr>
                                    <template v-if="order.payment_detail.payment_detail.credit_card">
                                        <tr class="">
                                            <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.paymentDetail.creditCard.owner') }}:</td>
                                            <td class="text-gray-800">{{ order.payment_detail.payment_detail.credit_card.holder_name }}</td>
                                        </tr>
                                        <tr class="">
                                            <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.payment.cols.paymentDetail.creditCard.number') }}:</td>
                                            <td class="text-gray-800">{{ order.payment_detail.payment_detail.credit_card.blurred_card_number }}</td>
                                        </tr>
                                    </template>
                                </template>
                            </table>
                        </div>
                    </template>

                    <template v-if="order.payment_detail && order.payment_detail.payment_method_detail && order.payment_detail.payment_method_detail.status == 'failed'">
                        <div class="separator separator-dashed mb-7"></div>

                        <div class="mb-10">
                            <h5 class="mb-3">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.paymentMethodDetailFailed') }}</h5>

                            <div class="mb-0">
                                <div class="d-flex align-items-center mb-1 justify-content-between">
                                    <span class="fw-bold text-gray-600">{{ order.payment_detail.payment_method_detail.failed_reason_msg }}</span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="order.shipping_detail">
                        <div class="separator separator-dashed mb-7"></div>

                        <div class="mb-10">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h5 class="mb-0">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.shipping.title') }}</h5>
                                <el-tooltip v-if="[5, 6].includes(order.shipping_detail.shipping_status_id)" :content="$t('pages.ecommerce.order.shipping.detail.title')" placement="top">
                                    <router-link :to="'/ecommerce/order-management/shipping/detail/' + order.id" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                        <span class="svg-icon svg-icon-3">
                                            <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                                        </span>
                                    </router-link>
                                </el-tooltip>
                            </div>
                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2">
                                <tr class="">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.shipping.cols.name') }}:</td>
                                    <td class="text-gray-800">{{ order.shipping_detail.shipping_method.name }}</td>
                                </tr>
                                <tr class="">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.shipping.cols.number') }}:</td>
                                    <td class="text-gray-800">{{ order.shipping_detail.shipping_tracking_number ?? "-" }}</td>
                                </tr>
                                <tr class="" v-if="order.shipping_detail && order.shipping_detail.shipping_total > 0">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.orderAndPayment.cols.shipping.cols.price') }}:</td>
                                    <td class="text-gray-800">{{ order.shipping_detail.shipping_total + ' ' + order.currency.suffix }}</td>
                                </tr>
                            </table>
                        </div>
                    </template>
                </div>
            </div>
            <div class="card card-flush pt-3 mb-5 mb-lg-10" v-if="order.admin">
                <div class="card-header">
                    <div class="card-title">
                        <h2>{{ $t('pages.ecommerce.order.detail.admin.title') }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0 fs-6">
                    <div class="mb-7">
                        <div class="d-flex align-items-center mb-1">
                            <span class="fw-bolder text-gray-800 text-hover-primary me-2">{{ order.admin.firstname + ' ' + order.admin.lastname }}</span>
                            <span :class="order.admin.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ order.admin.active ? $t('common.active') : $t('common.passive') }}</span>
                        </div>

                        <a :href="'mailto:'+ order.admin.email" class="fw-bold text-gray-600 text-hover-primary">{{ order.admin.email }}</a>
                    </div>
                </div>
            </div>
            <div class="card card-flush pt-3 mb-5 mb-lg-10">
                <div class="card-header">
                    <div class="card-title">
                        <h2>{{ $t('pages.ecommerce.order.detail.user.title') }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0 fs-6">
                    <div class="mb-7">
                        <div class="d-flex align-items-center mb-1">
                            <span class="fw-bolder text-gray-800 text-hover-primary me-2">{{ order.user.firstname + ' ' + order.user.lastname }}</span>
                            <span :class="order.user.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ order.user.active ? $t('common.active') : $t('common.passive') }}</span>
                        </div>

                        <a :href="'mailto:'+ order.user.email" class="fw-bold text-gray-600 text-hover-primary">{{ order.user.email }}</a>

                        <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 mt-3">
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.identificationNumber') }}:</td>
                                <td class="text-gray-800">{{ order.user.identification_number ?? "-" }}</td>
                            </tr>
                        </table>

                    </div>

                    <div class="separator separator-dashed mb-7"></div>

                    <div class="mb-7" v-if="order.address && order.address.invoice_type_id">
                        <h5 class="mb-3">{{ $t('pages.ecommerce.order.detail.user.cols.address.title') }}</h5>

                        <table class="table fs-6 fw-bold gs-0 gy-2 gx-2">
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.invoiceType') }}:</td>
                                <td class="text-gray-800">{{ getInvoiceTypeText(order.address.invoice_type_id) }}</td>
                            </tr>
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.name') }}:</td>
                                <td class="text-gray-800">{{ order.address.firstname + ' ' + order.address.lastname }}</td>
                            </tr>
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.identificationNumber') }}:</td>
                                <td class="text-gray-800">{{ order.address.identification_number ?? "-" }}</td>
                            </tr>
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.address') }}:</td>
                                <td class="text-gray-800">{{ order.address.address }}</td>
                            </tr>
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.district') }}:</td>
                                <td class="text-gray-800">{{ (order.address.district && order.address.district.length) ? order.address.district : "-" }}</td>
                            </tr>
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.location') }}:</td>
                                <td class="text-gray-800">{{ getLocation() }}</td>
                            </tr>
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.zipCode') }}:</td>
                                <td class="text-gray-800">{{ order.address.zip_code }}</td>
                            </tr>
                            <tr class="">
                                <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.phone') }}:</td>
                                <td class="text-gray-800"><a :href="'tel:'+ order.address.mobile_phone" class="p-0 fw-bold text-gray-600 text-hover-primary">{{ order.address.mobile_phone }}</a></td>
                            </tr>
                            <template v-if="order.address.invoice_type_id == 2">
                                <tr class="">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.companyName') }}:</td>
                                    <td class="text-gray-800">{{ order.address.company_name }}</td>
                                </tr>
                                <tr class="">
                                    <td class="text-gray-400">{{ $t('pages.ecommerce.order.detail.user.cols.address.cols.companyTaxOffice') }}:</td>
                                    <td class="text-gray-800">{{ order.address.company_tax_office }}</td>
                                </tr>
                            </template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_change_order" ref="updateOrderModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_change_order_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="orderForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_change_order_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-1">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.cols.orderStatus') }}</label>
                                <el-form-item prop="status_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.status_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(status, statusIndex) in orderStatus" :key="statusIndex" :value="status.id" :label="status.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-1">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.cols.shippingNumber') }}</label>
                                <el-form-item prop="shipping_tracking_number">
                                    <el-input v-model="form.data.shipping_tracking_number" type="text"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_add_order_note" ref="addOrderNoteModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ orderNote.form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitOrderNote()" :model="orderNote.form.data" ref="orderNoteForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-1">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.note.cols.note') }}</label>
                                <el-form-item prop="note" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="orderNote.form.data.note" type="textarea" rows="5"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="orderNote.form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="orderNote.form.loading">
                            <span v-if="!orderNote.form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="orderNote.form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_add_order_payment_collection" ref="addOrderPaymentCollectionModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ orderPaymentCollection.form.title }}</h2>
                    <div id="kt_modal_order_payment_collection_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitOrderPaymentCollection()" :model="orderPaymentCollection.form.data" ref="orderPaymentCollectionForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_order_payment_collection_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="d-flex flex-stack mb-6" v-if="Object.keys(order).length && order.payment_detail">
                                <!--begin::Label-->
                                <div class="me-5">
                                    <label class="fs-6 fw-bold mb-1">{{ $t('pages.ecommerce.order.paymentCollection.totalAmount') }}</label>
                                    <div class="fs-7 fw-bold text-gray-400">{{ getOrderSummaryTotal() + ' ' + order.currency.suffix }}</div>
                                </div>

                                <div class="me-5">
                                    <label class="fs-6 fw-bold mb-1">{{ $t('pages.ecommerce.order.paymentCollection.remainingTotal') }}</label>
                                    <div class="fs-7 fw-bold text-gray-400">{{ order.payment_detail.remaining_collect_amount + ' ' + order.currency.suffix }}</div>
                                </div>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.paymentCollection.cols.amount') }}</label>
                                <div class="d-sm-flex">
                                    <el-form-item class="mb-0" prop="amount" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="orderPaymentCollection.form.data.amount" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                    </el-form-item>
                                    <button type="button" class="btn btn-sm btn-danger align-self-center ms-sm-5 mt-4 mt-sm-0" v-on:click="transferRemainingAmount()">
                                        <span class="svg-icon svg-icon-1">
                                            <inline-svg src="/media/icons/duotune/arrows/arr060.svg" />
                                        </span>
                                        {{ $t('pages.ecommerce.order.paymentCollection.transferRemainingAmount') }}
                                    </button>
                                </div>
                            </div>

                            <div class="fv-row mb-1">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.paymentCollection.cols.note') }}</label>
                                <el-form-item prop="note">
                                    <el-input v-model="orderPaymentCollection.form.data.note" type="textarea" rows="5"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="orderPaymentCollection.form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="orderPaymentCollection.form.loading">
                            <span v-if="!orderPaymentCollection.form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="orderPaymentCollection.form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_change_usage_date" ref="changeUsageDateModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ itemUsageDate.form.title }}</h2>
                    <div id="kt_modal_change_usage_date_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onItemUsageDateSubmit()" :model="itemUsageDate.form.data" ref="changeUsageDateForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_change_usage_date_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.detail.itemUsageDate.cols.usageMinDate') }}</label>
                                <el-form-item prop="usage_min_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="itemUsageDate.form.data.usage_min_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledItemUsageDateMinDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.detail.itemUsageDate.cols.usageMaxDate') }}</label>
                                <el-form-item prop="usage_max_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="itemUsageDate.form.data.usage_max_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledItemUsageDateMaxDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="itemUsageDate.form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="itemUsageDate.form.loading">
                            <span v-if="!itemUsageDate.form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="itemUsageDate.form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>

</template>

<script>
import CustomTable from "@/components/custom-table";
import TreeProduct from '@/components/ecommerce/order/detail/treeProduct.vue';

export default {
    name: "_id",
    components: {
        CustomTable,
        TreeProduct
    },
    data() {
        return {
            orderNote: {
                fields: [
                    {
                        name: this.$t("pages.ecommerce.order.note.cols.createdBy"),
                        scopedSlots: {customRender: "createdBy"}
                    },
                    {
                        name: this.$t("pages.ecommerce.order.note.cols.note"),
                        key: "note"
                    },
                    {
                        name: this.$t("pages.ecommerce.order.note.cols.createdAt"),
                        scopedSlots: {customRender: "createdAt"}
                    },
                    {
                        name: this.$t("common.action"),
                        key: "action",
                        scopedSlots: {customRender: "actions"}
                    }
                ],
                actions: [
                    {
                        name: "new",
                        icon: "bi-plus-lg",
                        label: "btn.add"
                    }
                ],
                table: {
                    data: [],
                    loading: false
                },
                form: {
                    title: '',
                    loading: false,
                    data: {}
                }
            },
            orderPaymentCollection: {
                fields: [
                    {
                        name: this.$t("pages.ecommerce.order.paymentCollection.cols.createdBy"),
                        scopedSlots: {customRender: "createdBy"}
                    },
                    {
                        name: this.$t("pages.ecommerce.order.paymentCollection.cols.amount"),
                        scopedSlots: {customRender: "amount"}
                    },
                    {
                        name: this.$t("pages.ecommerce.order.paymentCollection.cols.note"),
                        scopedSlots: {customRender: "note"}
                    },
                    {
                        name: this.$t("pages.ecommerce.order.paymentCollection.cols.createdAt"),
                        scopedSlots: {customRender: "createdAt"}
                    },
                    {
                        name: this.$t("common.action"),
                        key: "action",
                        scopedSlots: {customRender: "actions"}
                    }
                ],
                actions: [
                    {
                        name: "new",
                        icon: "bi-plus-lg",
                        label: "btn.add"
                    }
                ],
                table: {
                    data: [],
                    loading: false
                },
                form: {
                    title: '',
                    loading: false,
                    data: {}
                }
            },
            orderDiscountCoupon : {
                fields: [
                    {
                        name: this.$t("pages.ecommerce.order.discountCoupon.cols.code"),
                        key: "discount_coupon.code"
                    },
                    {
                        name: this.$t("pages.ecommerce.order.discountCoupon.cols.type"),
                        scopedSlots: {customRender: "type"}
                    },
                    {
                        name: this.$t("pages.ecommerce.order.discountCoupon.cols.value"),
                        scopedSlots: {customRender: "value" }
                    },
                    {
                        name: this.$t("pages.ecommerce.order.discountCoupon.cols.total"),
                        scopedSlots: {customRender: "total" }
                    },
                ],
                types: {
                    1: "static",
                    2: "percent",
                },
                table: {
                    data: []
                }
            },
            form: {
                title: '',
                loading: false,
                data: {}
            },
            itemUsageDate: {
                form: {
                    title: '',
                    loading: false,
                    data: {}
                }
            },
            order: {},
            location: []
        }
    },
    computed: {
        orderID() {
            return this.$route.params.id;
        },
        orderStatus() {
            return this.$store.state.ecommerce.order.status.table.data;
        },
    },
    created() {
        if (this.orderID && !(this.orderID > 0)) {
            this.$router.push({
                path: "/ecommerce/order"
            });
        }
    },
    mounted() {
        if (this.orderID && this.orderID > 0) {
            this.loadOrder();
            this.$store.dispatch('ecommerce/order/status/get', {
                page: {pageSize: 1000}
            });

            this.eventBus.$on('changeItemUsageDate', (item) => {
                this.changeItemUsageDate(item);
            });
        }
    },
    methods: {
        loadOrder() {
            this.axios.get(this.endpoints['ecommerce_order'] + '/' + this.orderID).then((response) => {
                let data = response.data.data;
                this.orderNote.table.data = data.notes;
                this.orderPaymentCollection.table.data = data.payment_collections;
                this.orderDiscountCoupon.table.data = data.discount_coupons;
                this.order = data;

                this.loadLocation();
            }).finally(() => {
                this.orderNote.table.loading = false;
            });
        },
        loadLocation() {
            if (this.order.address && this.order.address.country_id && this.order.address.state_id) {
                this.axios.get(this.endpoints['localization_country_state'], {
                    params: {
                        country_id: this.order.address.country_id,
                        id: this.order.address.state_id
                    }
                }).then((response) => {
                    this.location = response.data.data
                })
            }
        },
        getLocation() {
            let location = [];

            if (this.location.length) {
                let state = this.location[0];

                location.push(state.name);

                if (this.order.address.city_id && state.cities && state.cities.length) {
                    let city = state.cities.filter((item) => {
                        return item.id == this.order.address.city_id
                    });

                    location.push(city.length ? city[0].name : "-");
                }
            }

            return location.length ? location.reverse().join(' / ') : "-";
        },
        newOrderNote() {
            this.orderNote.form.data = {
                order_id: this.orderID
            };
            this.orderNote.form.title = this.$t("pages.ecommerce.order.note.newOrderNote");
            this.showModal(this.$refs.addOrderNoteModal);
        },
        changeOrderStatus() {
            this.form.data = {
                status_id: this.order.status.id,
                shipping_tracking_number: (this.order.shipping_detail ? this.order.shipping_detail.shipping_tracking_number : undefined)
            };
            this.form.title = this.$t("pages.ecommerce.order.status.editOrderStatus");
            this.showModal(this.$refs.updateOrderModal);
        },
        onSubmitOrderNote() {
            this.$refs.orderNoteForm.validate((valid) => {
                if (valid) {
                    this.orderNote.form.loading = true;

                    this.axios.post(this.endpoints['ecommerce_order_note'], this.orderNote.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.loadOrder();
                            this.hideModal(this.$refs.addOrderNoteModal);
                        }, () => {
                            this.orderNote.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.orderNote.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        onSubmit() {
            this.$refs.orderForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    this.axios.put(this.endpoints['ecommerce_order'] + '/' + this.orderID, this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.loadOrder();
                            this.hideModal(this.$refs.updateOrderModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        deleteOrderNoteRecord(id) {
            this.$store.dispatch("ecommerce/order/note/delete", {
                id: id
            }).then((successDeleted) => {
                if (successDeleted) {
                    this.loadOrder();
                }
            });
        },
        getInvoiceTypeText(id) {
            let types = {
                1: this.$t('common.individual'),
                2: this.$t('common.corporate')
            };

            return types[id];
        },
        getOrderSummaryTotal() {
            let total = parseFloat(this.order.total);

            if (this.order.shipping_detail && this.order.shipping_detail.shipping_total > 0) {
                total += parseFloat(this.order.shipping_detail.shipping_total)
            }

            if (this.order.payment_detail && this.order.payment_detail.installment_commission) {
                total += parseFloat(this.order.payment_detail.installment_commission);
            }

            return total.toFixed(2);
        },
        handleOrderNoteClickAction(name) {
            switch (name) {
                case "new":
                    this.newOrderNote();
                    break;

                default:
                    break;
            }
        },
        newOrderPaymentCollection() {
            this.orderPaymentCollection.form.data = {
                order_id: this.orderID
            };
            this.orderPaymentCollection.form.title = this.$t("pages.ecommerce.order.paymentCollection.newPaymentCollection");
            this.showModal(this.$refs.addOrderPaymentCollectionModal);
        },
        onSubmitOrderPaymentCollection() {
            this.$refs.orderPaymentCollectionForm.validate((valid) => {
                if (valid) {
                    this.orderPaymentCollection.form.loading = true;

                    this.axios.post(this.endpoints['ecommerce_order_payment_collection'], this.orderPaymentCollection.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.loadOrder();
                            this.hideModal(this.$refs.addOrderPaymentCollectionModal);
                        }, () => {
                            this.orderPaymentCollection.form.loading = false;
                        });
                    }).catch(error => {
                        if (error.response.data.message == 'amount_to_be_collected_cannot_be_greater_than_the_remaining_amount') {
                            error.response.data.message = this.$t('pages.ecommerce.order.paymentCollection.responseMessages.amountMustLessThanRemaining');
                        }

                        this.onResponseFailure(error.response.data, () => {
                            this.orderPaymentCollection.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        deleteOrderPaymentCollectionRecord(id) {
            this.$store.dispatch("ecommerce/order/paymentCollection/delete", {
                id: id
            }).then((successDeleted) => {
                if (successDeleted) {
                    this.loadOrder();
                }
            });
        },
        handleOrderPaymentCollectionClickAction(name) {
            switch (name) {
                case "new":
                    this.newOrderPaymentCollection();
                    break;

                default:
                    break;
            }
        },
        transferRemainingAmount(){
            this.orderPaymentCollection.form.data.amount = JSON.parse(JSON.stringify(this.order.payment_detail.remaining_collect_amount));
        },
        changeItemUsageDate(item){
            this.itemUsageDate.form.data = {
                item_id: item.id,
                usage_min_date: (item.usage_min_date ? this.$moment(item.usage_min_date).format('YYYY-MM-DD HH:mm:ss') : undefined),
                usage_max_date: (item.usage_max_date ? this.$moment(item.usage_max_date).format('YYYY-MM-DD HH:mm:ss') : undefined)
            };
            this.itemUsageDate.form.title = this.sprintf(this.$t("pages.ecommerce.order.detail.itemUsageDate.editItemUsageDatePattern"), [this.resolveDatum(item.product.translations, this.$root.defaultLanguage.id, 'language_id').title]);
            this.showModal(this.$refs.changeUsageDateModal);
        },
        onItemUsageDateSubmit(){
            this.$refs.changeUsageDateForm.validate((valid) => {
                if (valid) {
                    this.itemUsageDate.form.loading = true;

                    this.axios.put(this.endpoints['ecommerce_order_item_change_usage'] + '/' + this.order.id, {
                        items: [this.itemUsageDate.form.data]
                    }).then(response => {
                        this.onResponse(response.data, () => {
                            this.loadOrder();
                            this.hideModal(this.$refs.changeUsageDateModal)
                        }, () => {
                            this.itemUsageDate.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.itemUsageDate.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        disabledItemUsageDateMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.itemUsageDate.form.data.usage_max_date ? this.$moment(this.itemUsageDate.form.data.usage_max_date) : undefined;

            return endDate != undefined && startDate.valueOf() > endDate.valueOf();
        },
        disabledItemUsageDateMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.itemUsageDate.form.data.usage_min_date ? this.$moment(this.$moment(this.itemUsageDate.form.data.usage_min_date).format('YYYY-MM-DD')) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.summary-table {
    border: 1px solid #E4E6EF;
    border-radius: 4px;
}

.summary-table .table tr > td {
    border-top: 1px solid #EFF2F5;
}

.summary-table tr:first-child td {
    border-top: none;
}

</style>